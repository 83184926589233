export default {
    SwitchLanguage: {
        another: 'Use English'
    },
    Cesium: {
        detail:'详情',
        select:'请选择',
        layerControl:'图层控制',
        intensityMap:'烈度图',
        epicenter:'震源',
        hospital:'显示医院',
        materialTip:'默认的物资总量为1000个',
        material:'显示救援物资分配',
        materialCal:'正在进行物资分配计算',
        expand: '展开',
        materialNum: '设置物资分配总量:',
        materialArea: '设置物资分配地区数量:',
        cal:'计算',
        jump:'跳转到所选震区',
        pathPlan:'救援路径规划',
        car:'驾车',
        walk:'步行',
        bike:'电动车',
        selectPos:'选取救援点',
        startPlan:'开始路径规划',
        stopPlan:'关闭',
        initTip:'初次加载可能稍慢，请耐心等待',
        outIntensity:'最外圈烈度',
        posDesc:'您所点击的位置所表示的区域',
        startPoint:'起点',
        IMG_C:'全球影像地图服务(经纬度)',
        IMG_W:'全球影像地图服务(墨卡托)',
        VEC_C:'全球矢量地图服务(经纬度)',
        VEC_W:'全球矢量地图服务(墨卡托)',
        TER_C:'全球地形晕渲服务(经纬度)',
        TER_W:'全球地形晕渲服务(墨卡托)',
        IBO_C:'全球境界(经纬度)',
        IBO_W:'全球境界(墨卡托)',
        calTip:'请耐心等待，后台正在重新进行物资分配~',
        calSuc:'物资分配计算完成',
        calFail:'物资分配计算失败',
        planEnd:'结束路径规划,如想要开启路径规划功能，请重新选点',
        planStart:'现在可以开始选取救援点啦~',
        planError:'您还没有选取点，请点击屏幕进行选点',
        planing:'开始路径规划！',
        name:'地震名称',
        level:'震级',
        pos:'震源经纬度',
        intensity:'烈度',
        time:'发生时间',
        hospitalName:'医院名称',
        address:'医院地址',
        hospitalPOS:'经纬度',
        city:'所在省市',
        type:'类型',
        count:'物资数量',
        longRadius:'长轴半径',
        shortRadius:'短轴半径',
        intensityDetail:'(选中区域外圈等烈度线)'
    },
    AddEarthquake: {
        add: '添加地震',
        name: '地震名称',
        level: '震级',
        lon: '震源经度',
        lat: '震源纬度',
        time: '地震发生时间',
        selectTime: '选择地震发生时间',
        submit: '提交',
        cancel: '取消',
        success: '添加成功',
        fail: '添加失败',
    },
    EstimateEarthquake: {
        estimate: '评估地震情况',
        quickAnalyze: '地震灾情快速评估',
        name: '地震名称',
        level: '震级',
        lonlat: '震源经纬度',
        highIntensity:'最高烈度',
        time: '地震发生时间',
        population: '估算的人口密度：',
        referenceOnly: '人/km²（计算结果仅供参考）',
        economy: '估算的经济损失：',
        death: '估算的死亡人数：',
        error: '暂时不支持该地区的灾情分析！',
        unitYuan: '亿元',
        unitPerson: '人',
    },
    EarthquakeSelect: {
        list: '地震列表',
        selectEarthquake: '选择地震',
        enterName: '输入地震名称搜索',
        filtering: '条件筛选',
        range: '时间范围',
        to: '至',
        startDate: '最早日期',
        endDate: '最晚日期',
        area: '限制区域',
        search: '筛选',
        showAll: '显示全部',
        select: '选择',
        selected: '已选择',
        jump: '跳转',
        delete: '删除',
        name: '地震名称',
        level: '震级',
        lonlat: '震源经纬度',
        highIntensity: '最高烈度',
        time: '地震发生时间',
        unLimited: '不限',
        china: '中国附近',
        today: '最近一天',
        week: '最近一周',
        month: '最近一个月',
        threeMonth: '最近3个月',
        halfYear: '最近半年',
        year: '最近一年',
    }
}
