export default {
    SwitchLanguage: {
        another: '切换中文'
    },
    Cesium: {
        detail:'Detail',
        select:'Please select',
        layerControl:'Layer Control',
        intensityMap:'Intensity Map',
        epicenter:'Epicenter',
        hospital:'Show Hospital',
        materialTip:'Default material quantity is 1000',
        material:'Distribution',
        materialCal:'Calculating material allocation',
        expand: 'Expand',
        materialNum: 'Set material allocation quantity:',
        materialArea: 'num of area:',
        cal:'Calculate',
        jump:'Jump to',
        pathPlan:'Rescue Path Planning',
        car:'Car',
        walk:'Walk',
        bike:'Bike',
        selectPos:'Select Point',
        startPlan:'Start',
        stopPlan:'Close',
        initTip:'The first time loading may be slow, please wait patiently',
        outIntensity:'Outermost Intensity',
        posDesc:'The area represented by the position you clicked',
        startPoint:'Start Point',
        IMG_C:'Global Imagery Map Service (longitude and latitude)',
        IMG_W:'Global Imagery Map Service (Mercator)',
        VEC_C:'Global Vector Map Service (longitude and latitude)',
        VEC_W:'Global Vector Map Service (Mercator)',
        TER_C:'Global Terrain Relief Service (longitude and latitude)',
        TER_W:'Global Terrain Relief Service (Mercator)',
        IBO_C:'Global Boundary (longitude and latitude)',
        IBO_W:'Global Boundary (Mercator)',
        calTip:'Please wait patiently, the background is recalculating the material allocation',
        calSuc:'Material allocation calculation completed',
        calFail:'Material allocation calculation failed',
        planEnd:'End path planning, if you want to open the path planning function, please reselect the point',
        planStart:'Now you can start selecting rescue points~',
        planError:'You have not selected a point, please click on the screen to select a point',
        planing:'Start path planning!',
        name:'Name',
        level:'Level',
        pos:'Position',
        intensity:'Intensity',
        time:'Time',
        hospitalName:'Name',
        address:'Address',
        hospitalPOS:'Position',
        city:'City',
        type:'Type',
        count:'Material Quantity',
        longRadius:'Long Axis Radius',
        shortRadius:'Short Axis Radius',
        intensityDetail:'(Outermost Intensity Line of Selected Area)'
    },
    AddEarthquake: {
        add: 'Add Earthquake',
        name: 'Name',
        level: 'Magnitude',
        lon: 'Longitude',
        lat: 'Latitude',
        time: 'Time',
        selectTime: 'Select Time',
        submit: 'Submit',
        cancel: 'Cancel',
        success: 'Add Success',
        fail: 'Add Fail',
    },
    EstimateEarthquake: {
        estimate: 'Estimate Earthquake',
        quickAnalyze: 'Quick Analyze',
        name: 'Name',
        level: 'Magnitude',
        lonlat: 'Position',
        highIntensity:'Intensity',
        time: 'Time',
        population: 'Population Density:',
        referenceOnly: 'person/km² (Reference Only)',
        economy: 'Economy Lost:',
        death: 'Casualties:',
        error: 'Not support this area',
        unitYuan: 'RMB100mn',
        unitPerson: '(person)',
    },
    EarthquakeSelect: {
        list: 'Earthquake List',
        selectEarthquake: 'Select Earthquake',
        enterName: 'Enter Earthquake Name',
        filtering: 'Filtering',
        range: 'Range',
        to: '-',
        startDate: 'Start Date',
        endDate: 'End Date',
        area: 'Restrict Area',
        search: 'Search',
        showAll: 'Show All',
        select: 'Select',
        selected: 'Selected',
        jump: 'Jump',
        delete: 'Delete',
        name: 'Name',
        level: 'Magnitude',
        lonlat: 'Position',
        highIntensity: 'Intensity',
        time: 'Time',
        unLimited: 'Unlimited',
        china: 'China',
        today: 'Today',
        week: 'This Week',
        month: 'This Month',
        threeMonth: 'This Quarter',
        halfYear: 'This Half Year',
        year: 'This Year',
    }
}
